import React, { useEffect, useState } from 'react';
import useColumnSearch from '../../../hooks/useColumnSearch';
import { useDebouncedValue } from '../../../hooks/useDebouncedValue';
const FilterColumnInput = ({ placeholder, searchKey, columnKey }) => {
  const [value, setValue] = useState();

  const [debouncedValue] = useDebouncedValue(value, 500);

  const { handleInputChange, searchInput } = useColumnSearch(
    searchKey,
    'page',
    columnKey,
  );

  useEffect(() => {
    handleInputChange(debouncedValue);
  }, [debouncedValue]);

  return (
    <input
      className="w-[150px] h-[30px] text-xs border px-2 rounded-sm font-medium"
      placeholder={`Search ${placeholder}`}
      onChange={e => {
        setValue(e.target.value ? e.target.value : '');
      }}
      value={typeof value !== 'string' ? searchInput : value}
    />
  );
};

export default FilterColumnInput;
